<template>
    <div id="burgerBtn" v-on:click="handleHamburger"/>
</template>
<script>
export default {
  methods: {
    handleHamburger() {
      const mobile = document.getElementById('mobile');
      mobile.classList.toggle('navigation');
    },
  },
};
</script>
<style lang="sass" scoped>
    @import './HamburgerMenu.sass'
</style>
